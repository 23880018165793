@use 'abstracts/variables' as v;
@use 'core/typography' as t;
@use 'ng-zorro-skeleton' as n-z-s;
@use 'ng-zorro-dialog' as n-z-d;
@use 'ng-zorro-input' as n-z-i;
@use 'ng-zorro-alert' as n-z-a;
@use 'ng-zorro-collapse-panel' as n-z-c-p;

// select
nz-select {
  width: 100%;
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 4px;
      height: 40px;
      border: 1px solid v.$grey-6;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        @include t.regular-1;

        line-height: 40px;
        padding-right: 20px;
        text-align: left;
      }

      .ant-select-selection-placeholder {
        color: v.$grey-8;
      }
    }
  }

  .ant-select-arrow {
    top: 40%;
    width: 20px;
    height: 20px;

    mat-icon {
      width: 20px;
      height: 20px;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      box-shadow: none;
      border: 1px solid v.$grey-8;
    }
  }

  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-color: v.$grey-8;
      }
    }
  }
}

.ant-select-item {
  font-family: Poppins, sans-serif;

  &.ant-select-item-option-selected,
  &.ant-select-item-option-active {
    &:not(.ant-select-item-option-disabled) {
      background: v.$green-1;
    }
  }
}

nz-option-container {
  .cdk-virtual-scroll-viewport.cdk-virtual-scroll-orientation-vertical {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: v.$primary;
      border-radius: 5px;
    }
  }
}

// pagination
.ant-pagination-item {
  @include t.regular-2;

  color: v.$grey-9;
  line-height: 30px;
}

.ant-pagination-item-active {
  background-color: v.$primary;
  border: 1px solid v.$primary;

  a {
    color: v.$grey-1;
  }

  &:hover {
    background-color: darken(v.$primary, 13);
    border: 1px solid darken(v.$primary, 13);

    a {
      color: v.$grey-1;
    }
  }
}

// tooltip
.ant-tooltip-inner {
  @include t.medium-2;

  background-color: v.$grey-10;
  border-radius: 4px;
  padding: 10px;
  color: v.$grey-1;
}

// checkbox
.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

// dropdown
.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    @include t.medium-1;
  }
}
