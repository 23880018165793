/** INFO: Please keep class names in consistensy with Tailwind.css (!) */

/* Text Positioning */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Text Decorations */
.underline {
  text-decoration-line: underline;
}

.overline {
  text-decoration-line: overline;
}

.line-through {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: none;
}

.capitalize {
  text-transform: capitalize;
}

// rename to m-0
.no-margin {
  margin: 0;
}

.m-0 {
  margin: 0;
}

// rename to cursor-pointer
.pointer {
  cursor: pointer;
}

/* Font Weight */
.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

/* Colors */
.text-green-5 {
  color: #00ac45;
}

.text-blue-5 {
  color: #597ef7;
}

.text-blue-6 {
  color: #2F54EB;
}

.text-red-7 {
  color: #cf1322;
}

.text-red-6 {
  color: #CF1322;
}

.text-grey-6 {
  color: #BFBFBF;
}

.text-grey-10 {
  color: #000;
}

.text-volcano-6 {
  color: #FA541C;
}
