@use 'core/breakpoint' as bp;

.hide-sm {
  @include bp.from-to(0, bp.$small-screen) {
    display: none !important;
  }
}

.hide-md {
  @include bp.from-to(bp.$small-screen, bp.$medium-screen) {
    display: none !important;
  }
}

.hide-lg {
  @include bp.from-to(bp.$medium-screen, bp.$large-screen) {
    display: none !important;
  }
}

.hide-xl {
  @include bp.from-to(bp.$large-screen, bp.$extra-large-screen) {
    display: none !important;
  }
}

.hide-till-sm {
  @include bp.till(bp.$small-screen) {
    display: none !important;
  }
}

.hide-till-md {
  @include bp.till(bp.$medium-screen) {
    display: none !important;
  }
}

.hide-till-lg {
  @include bp.till(bp.$large-screen) {
    display: none !important;
  }
}

.hide-till-xl {
  @include bp.till(bp.$extra-large-screen) {
    display: none !important;
  }
}

.hide-from-sm {
  @include bp.from(bp.$small-screen) {
    display: none !important;
  }
}

.hide-from-md {
  @include bp.from(bp.$medium-screen) {
    display: none !important;
  }
}

.hide-from-lg {
  @include bp.from(bp.$large-screen) {
    display: none !important;
  }
}

.hide-from-xl {
  @include bp.from(bp.$extra-large-screen) {
    display: none !important;
  }
}
