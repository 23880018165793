@use 'abstracts/variables' as v;
@use 'core/typography' as t;
@use 'theme/mixins' as m;

@mixin input() {
  @include t.regular-1;
  @include m.scrollbar-vertical;

  border: 1px solid v.$grey-6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 12px;
  outline: none;
  display: block;
  width: 100%;
  resize: none;

  &::placeholder {
    color: v.$grey-8;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &:hover,
  &:focus {
    border: 1px solid v.$grey-8;
  }

  &:disabled {
    border: 1px solid v.$grey-6;
    opacity: 0.6;
  }

  &.ng-invalid.ng-touched {
    background: rgb(255 241 240 / 50%);
    border: 1px solid v.$accent;
  }
}

@mixin label() {
  @include t.medium-1;

  color: v.$grey-10;
  margin-bottom: 4px;
  display: block;

  &.required {
    &::after {
      content: '*';
      display: inline;
      color: v.$secondary;
      font-size: inherit;
      font-family: inherit;
    }
  }
}
