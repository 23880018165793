@use 'abstracts/variables' as v;

@mixin text-truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin scrollbar-vertical() {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: v.$primary;
    border-radius: 5px;
  }
}

@mixin hidden-scrollbar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
