app-objective {
  .scrollable-content {
    position: relative;
    overflow: auto;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: rgb(0 0 0 / 10%);
    max-height: 530px;
    margin-bottom: 16px;

    table {
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      font-size: 12px;
      line-height: 16px;
      background-color: #fff;
      color: rgb(24 37 58 / 87%);
      min-width: 100%;

      &.fixed-columns {
        td,
        th {
          &:first-child {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
          }

          &:nth-child(2) {
            width: 190px;
            min-width: 190px;
            max-width: 190px;
            box-shadow: 8px 0 8px rgb(0 0 0 / 8%);
          }
        }

        th {
          &:first-child {
            left: 0;
            z-index: 2;
            color: rgb(24 37 58 / 87%);
          }

          &:nth-child(2) {
            left: 32px;
            z-index: 2;
            color: rgb(24 37 58 / 87%);
          }
        }

        td {
          &:nth-child(1) {
            position: sticky;
            left: 0;
            z-index: 1;
          }

          &:nth-child(2) {
            position: sticky;
            left: 32px;
            z-index: 1;
          }
        }
      }

      thead {
        box-shadow: 0 8px 8px rgb(0 0 0 / 8%);

        th {
          position: sticky;
          top: 0;
          border-width: 1px 0;
          border-style: solid;
          border-color: rgb(0 0 0 / 10%);
          background-color: #fff;
          font-weight: 500;
          text-align: left;
          color: rgb(24 37 58 / 60%);

          &.row-index {
            color: rgb(24 37 58 / 87%);
          }
        }
      }

      tbody {
        tr {
          td {
            word-break: break-word;

            input:checked {
              background-color: #00ac45;
            }

            .td-hint {
              position: relative;
              cursor: pointer;
              appearance: none;
              outline: none;
              width: 16px;
              height: 16px;
              background-image: url('../../assets/images/icons/uil_question-circle-.svg');
              background-position: bottom;
              background-size: contain;
              background-repeat: no-repeat;

              &:hover,
              &:checked {
                &::after {
                  content: attr(data-hint);
                  position: absolute;
                  right: -48px;
                  bottom: 0;
                  padding: 8px 12px;
                  margin: 14px;
                  background: #fff;
                  width: 250px;
                  color: rgb(24 37 58 / 87%);
                  border-radius: 4px;
                  border: 1px solid #dde3ed;
                  box-shadow: 0 0 12px rgb(88 96 110 / 12%);
                  z-index: 9999;
                  white-space: nowrap;
                  overflow: scroll;
                }
              }
            }
          }

          a {
            position: relative;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
          }

          &:hover {
            td {
              background: #fafcf7;
            }

            a {
              padding-right: 24px;
              margin: -8px 0;

              &::after {
                content: attr(href);
                display: block;
                color: rgb(24 37 58 / 38%);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &::before {
                content: url('/assets/images/icons/seo/mdi_open_in_new.svg');
                position: absolute;
                top: calc(50% - 12px);
                right: 0;
                width: 24px;
                height: 24px;
              }
            }
          }

          &:not(:last-child) {
            td {
              border-bottom: 1px solid rgb(0 0 0 / 10%);
            }
          }
        }
      }

      th,
      td {
        padding: 16px;
        min-width: 64px;
        max-width: 20%;
        background-color: #fff;
        word-break: normal;

        &.row-index {
          width: 32px;
          min-width: 32px;
          max-width: 32px;
        }
      }
    }
  }
}
