$small-screen: 680px;
$medium-screen: 1150px;
$large-screen: 1750px;
$extra-large-screen: 2550px;

@mixin tablet() {
  @media (min-width: $small-screen) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $medium-screen) {
    @content;
  }
}

@mixin large-screen() {
  @media (min-width: $large-screen) {
    @content;
  }
}

@mixin extra-large-screen() {
  @media (min-width: $extra-large-screen) {
    @content;
  }
}

@mixin mobile-only() {
  @media (max-width: $small-screen - 1) {
    @content;
  }
}

@mixin from-to($from, $to) {
  @media (min-width: $from) and (max-width: $to - 1px) {
    @content;
  }
}

@mixin till($breakpoint) {
  @media (max-width: $breakpoint - 1px) {
    @content;
  }
}

@mixin from($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
