/* These styles we use for tables which are in different objectives */
@use 'assets/scss/fixed-table.scss' as ft;

/* You can add global styles to this file, and also import other style files */
@use 'assets/scss/core/font-declaration';
@use 'assets/scss/core/typography' as t;

/* Classes which help to control different state of screen width based on conditions */
@use 'assets/scss/abstracts/helpers.scss' as h;

/* Global import of text util classes */
@use 'assets/scss/abstracts/text-utils';
@use 'assets/scss/abstracts/display-utils';

@use 'ng-zorro/ng-zorro-overrides' as n-z-o;

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  @include t.regular-1;

  background: #f5f7fa !important;
  color: rgb(24 37 58 / 87%);
}

html {
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: inherit;
}

pre {
  white-space: normal;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex='-1']:focus {
  outline: none !important;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

a {
  color: #28a745;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #19692c;
    text-decoration: none;
  }

  &:active,
  &:visited {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

b,
strong {
  font-weight: 700;
}

h1 {
  margin-bottom: 16px;
}

em {
  font-style: normal;
  color: #00ac45;
}

/* Hides Google ReCaptcha V3 badge */
.grecaptcha-badge {
  visibility: hidden;
}
