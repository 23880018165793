$primary: #00ac45;
$secondary: #2f54eb;
$accent: #cf1322;
$warning: #fb7c38;
$grey-1: #fff;
$grey-2: #fafafa;
$grey-3: #f5f5f5;
$grey-4: #e8e8e8;
$grey-5: #d9d9d9;
$grey-6: #bfbfbf;
$grey-7: #8c8c8c;
$grey-8: #595959;
$grey-9: #262626;
$grey-10: #000;
$green-1: #dafce7;
$green-2: #a0e7bc;
$green-3: #73d69b;
$green-4: #44c577;
$green-5: #00ac45;
$green-6: #009d3e;
$green-7: #008635;
$green-8: #006c2b;
$green-9: #005622;
$green-10: #003d18;
$green-11: #beffd8;
$green-12: #e3ffee;
$lime-3: #eaff8f;
$lime-5: #bae637;
$lime-4: #d3f261;
$yellow-1: #feffe6;
$yellow-6: #fadb14;
$yellow-5: #ffec3d;
$gold-2: #fff1b8;
$gold-3: #ffe58f;
$gold-5: #ffc53d;
$gold-6: #faad14;
$blue-1: #e6f7ff;
$blue-2: #bae7ff;
$blue-3: #ced4e5;
$blue-4: #69c0ff;
$blue-5: #597ef7;
$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-8: #0050b3;
$blue-9: #003a8c;
$blue-10: #070386;
$geekblue-1: #f0f5ff;
$geekblue-2: #d6e4ff;
$geekblue-3: #adc6ff;
$geekblue-5: #597ef7;
$geekblue-6: #2f54eb;
$geekblue-7: #1d39c4;
$geekblue-8: #10239e;
$volcano-4: #ff9c6e;
$volcano-6: #fa541c;
$red-4: #ff7875;
$red-5: #ff4d4f;
$red-7: #cf1322;
$red-8: #9a000c;
$purple-2: #ebeefd;
$purple-3: #c3eafe;
$purple-4: #b37feb;
$purple-5: #9254de;
$purple-6: #722ed1;
$purple-7: #531dab;
$purple-8: #391085;
$purple-9: #641acb;
$magenta-7: #c41d7f;
$magenta-9: #780650;
$background: #f4f5f7;
$orange-1: #fff7e6;
$orange-2: #ffe7ba;
$orange-6: #fa8c16;
$orange-7: #d46b08;
$cyan-7: #08979c;
