@use 'abstracts/variables' as v;

/* Deprecated */
@mixin paragraph {
  @include regular-1;

  color: v.$grey-9;
  margin: 0 0 1em;
}

@mixin hint-regular {
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
}

@mixin regular-3 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
}

@mixin regular-2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
}

@mixin regular-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

@mixin semibold-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

@mixin semibold-2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

@mixin semibold-3 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

/* TODO: medium-1 and medium-2 are mixed */
@mixin medium-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

@mixin medium-2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@mixin title-3 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}

@mixin title-2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@mixin title-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@mixin title-regular-3 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin huge-text {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

@mixin huge-text-2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
}

@mixin huge-text-bold-2 {
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@mixin header-3 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

@mixin header-2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
}

@mixin header-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
}

@mixin big-text-regular-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
}

@mixin big-text-semibold-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 30px;
  font-weight: 600;
}

@mixin huge-header-1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
}

@mixin handshake-header-2 {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

@mixin handshake-header-3 {
  font-family: Poppins, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}

@mixin handshake-header-1 {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

@mixin handshake-text-regular-1 {
  font-family: Poppins, sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

@mixin handshake-text-regular-2 {
  font-family: Poppins, sans-serif;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
}

@mixin handshake-text-semibold-1 {
  font-family: Poppins, sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

@mixin handshake-text-semibold-2 {
  font-family: Poppins, sans-serif;
  font-size: 7px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
}

@mixin handshake-small-version-button {
  font-family: Poppins, sans-serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

@mixin popup-mobile-text {
  font-family: Poppins, sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

@mixin small-text {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

@mixin small-text-medium {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

@mixin italic-text {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
}

@mixin positive() {
  color: #2ba860;
}
