@use 'core/breakpoint' as bp;
@use 'theme/mixins' as m;

@include bp.mobile-only {
  .ant-modal-wrap {
    .full-screen-modal {
      @include m.hidden-scrollbar;

      min-width: 100vw;
      margin: 0;

      .ant-modal-content {
        min-height: 100vh;
      }
    }
  }
}

.keyword-opportunity-modal {
  @include m.hidden-scrollbar;

  max-width: 100vw;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  border-radius: unset;

  .content {
    padding: 0 !important;
    max-height: unset !important;
  }
}

.video-modal {
  width: 800px !important;
  height: 450px !important;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;
  }
}

.meetings-modal {
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
    max-width: 100vw;
    max-height: 100vh;

    .ant-modal-body {
      padding: 0;
    }
  }
}

.no-padding {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
}
