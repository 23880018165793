@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../../fonts/poppins-v20-latin-regular.woff2') format('woff2'),
  url('../../fonts/poppins-v20-latin-regular.woff') format('woff');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../../fonts/poppins-v20-latin-500.woff2') format('woff2'),
  url('../../fonts/poppins-v20-latin-500.woff') format('woff');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../../fonts/poppins-v20-latin-600.woff2') format('woff2'),
  url('../../fonts/poppins-v20-latin-600.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  font-weight: 300;
  font-style: normal;
  src: local(''), url('../../fonts/Roboto-Light.woff2') format('woff2'),
  url('../../fonts/Roboto-Light.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: local(''), url('../../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../../fonts/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: local(''), url('../../fonts/Roboto-Medium.woff2') format('woff2'),
  url('../../fonts/Roboto-Medium.woff') format('woff');
}
